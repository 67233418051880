import "./reset.css";
import "./index.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Footer } from "./components/footer/footer";
import { NavBar } from "./components/navbar/navbar";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./services/firebase/authContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(

  <AuthProvider>

    <React.StrictMode>
      <BrowserRouter>
        <div className="dev-folder">
          <figure id="dev"></figure>
          <h3>DEV</h3>
          <h5>Fase-1</h5>
        </div>
        <NavBar />
        <App />
        <Footer />
      </BrowserRouter>
    </React.StrictMode>
  </AuthProvider>
);
