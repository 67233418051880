import "./style.scss";
import React from "react";
import { BuscaForm } from "../../../components/busca-form/busca-form";
import TextSlider from "./textslider.jsx"
export function Introduction() {
  return (
    <section className="introduction">
      <TextSlider/>
      
    </section>
  );
}
