import React, { useState, createContext, useContext } from "react";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "@firebase/auth";
import { useAuth } from "../../services/firebase/authContext";

const provider = new GoogleAuthProvider();
const auth = getAuth();

interface GoogleSignInProps {
  onClose: () => void;
}
const GoogleSignIn: React.FC <GoogleSignInProps>= ({onClose}) => {
  const { setUser } = useAuth();

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);

      // Token do Google Auth
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;

      // Recolhimento de informações após o login
      const user = result.user;
      const displayName = user.displayName;
      setUser(user);
      console.log("User:", user);
      console.log("Token:", token);

      onClose();

    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
      const credential = GoogleAuthProvider.credentialFromError(error);

      console.error("Error Code:", errorCode);
      console.error("Error Message:", errorMessage);
      console.error("Email:", email);
    }
  };

  return (
    <div className="login-icon" onClick={handleGoogleSignIn}>
      <figure id="google" title="Login com Google" />
      <h3></h3>
      <span>Continuar com o Google</span>
    </div>
  );
};

export default GoogleSignIn;
