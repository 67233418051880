import "./media.scss"
import data from "../../services/data/primary.json"
const contato = data.tel
const mensagem = data.mensagem
const Media = () => {
    return(
        <div className="media contact-form ">
            <h2>Entre em contato com nossa equipe</h2>
            <figure id="team"></figure>
            <h3>Conheça nossa equipe</h3>
            
            <a href={`https://wa.me/${contato}?text=${mensagem}`}><figure id="whatsapp"></figure></a> 
            <h3>Acesse o whatsapp</h3>
        </div>
    );
};
export default Media